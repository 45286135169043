import React from 'react';
import { Document } from '@contentful/rich-text-types';
import { Parallax } from 'react-scroll-parallax';
import FlexibleLink from '../../ui/FlexibleLink';
import Headline from '../../ui/Headline';
import RichText from '../../ui/RichText';
import Container from '../../ui/Grid/Container';
import Row from '../../ui/Grid/Row';
import Col from '../../ui/Grid/Col';
import { ILink } from '../../ui/FlexibleLink/type';
import * as styles from './styles.module.scss';
import useWindowDimensions from '../../../hooks/useWindowDimensions';
import cn from 'classnames';

type FullwidthTextTeaserProps = {
  headline: string;
  headlineLevel?: 'H2' | 'H3' | 'H4';
  text: Document;
  link: ILink;
  fullWidth?: boolean;
  nextItem?: string;
  prevItem?: string;
};

const FullwidthTextTeaser: React.FC<FullwidthTextTeaserProps> = ({
  headline,
  headlineLevel,
  text,
  link,
  fullWidth = true,
  nextItem,
  prevItem,
}) => {
  const { width } = useWindowDimensions();

  let headlineLvl: React.ComponentProps<typeof Headline>['level'];

  switch (headlineLevel) {
    case 'H2':
      headlineLvl = 2;
      break;
    case 'H3':
      headlineLvl = 3;
      break;
    case 'H4':
      headlineLvl = 4;
      break;
    default:
      headlineLvl = 2;
      break;
  }

  return (
    <>
      {fullWidth ? (
        <Container additionalClass={styles.containerFullWidth}>
          <Row>
            <Col tablet={6} mobile={12}>
              <Parallax y={[-40, 40]} disabled={width < 640}>
                <Headline level={2} additionalClass="desktop:mr-50 mb-35 tablet:mb-0" color="Black">
                  {headline}
                </Headline>
              </Parallax>
            </Col>
            <Col tablet={6} mobile={12} additionalClass={styles.textCol}>
              <div className={styles.textWrapper}>
                <RichText content={text} additionalClass={styles.text} />
              </div>
              {link && <FlexibleLink link={link} additionalClass={styles.link} />}
            </Col>
          </Row>
        </Container>
      ) : (
        <Container
          additionalClass={cn(styles.containerHalfWidth, {
            [styles.withoutSpace]:
              nextItem === 'ContentfulComponentHalfwidthTextTeaser' ||
              prevItem === 'ContentfulComponentHalfwidthTextTeaser',
          })}
        >
          <Headline level={headlineLvl} additionalClass="mb-25 tablet:mb-0 tablet:mr-50" color="Black">
            {headline}
          </Headline>
          <div className={styles.textWrapper}>
            <RichText content={text} additionalClass={styles.text} />
          </div>
        </Container>
      )}
    </>
  );
};

export default FullwidthTextTeaser;
